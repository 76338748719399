<template>
  <div class="super-rgpd">
    <UiBanner fluid :title="$t('superRgpd.list.name')">
      <template v-slot:actions>
        <div class="tw-flex tw-items-center">
          <v-text-field
            v-model="search"
            :value="search"
            :prepend-inner-icon="`${icons.mdiMagnify}`"
            :label="$t('superRgpd.list.search.label')"
            hide-details
            outlined
            solo
            flat
            dense
            clearable
            @input="searchInput"
          />

          <v-btn class="tw-ml-4 tw-rounded-md" color="primary" @click="goToCreate">
            {{ $t('superRgpd.list.search.button') }}
          </v-btn>
        </div>
      </template>
    </UiBanner>

    <UiContainer no-padding>
      <div class="super-rgpd__table-container">
        <v-data-table
          style="cursor: pointer"
          class="super-rgpd__table-container__table"
          height="100%"
          item-key="id"
          fixed-header
          :loading="refreshing"
          :headers="headers"
          :items="superRgpds"
          :options.sync="options"
          :search="search"
          :server-items-length="superRgpdsPagination.total"
          :footer-props="{ itemsPerPageOptions: [25, 50, 100] }"
        >
          <template v-slot:[`item.isMultilanguage`]="{ item }">
            <v-icon v-if="item.isMultilanguage" color="success">{{ icons.mdiCheckCircle }}</v-icon>
            <v-icon v-else color="error">{{ icons.mdiCloseCircle }}</v-icon>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-icon v-if="item.status === '1'" color="success">{{ icons.mdiCheckCircle }}</v-icon>
            <v-icon v-else color="error">{{ icons.mdiCloseCircle }}</v-icon>
          </template>
        </v-data-table>
      </div>
    </UiContainer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiMagnify, mdiCheckCircle, mdiCloseCircle } from '@mdi/js'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'
import { debounce } from '@/utils/debounce.util'

export default {
  name: 'SuperRgpdList',
  components: {
    UiBanner,
    UiContainer,
  },
  data: () => ({
    icons: {
      mdiMagnify,
      mdiCheckCircle,
      mdiCloseCircle,
    },
    refreshing: false,
    search: '',
    options: {
      itemsPerPage: 25,
    },
  }),
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadSuperRgpds()
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      superRgpds: state => state.superRgpd.superRgpds,
      superRgpdsPagination: state => state.superRgpd.superRgpdsPagination,
    }),
    headers() {
      return [
        {
          text: this.$t('superRgpd.list.headers.game_reference'),
          value: 'game_reference',
          sortable: false,
        },
        {
          text: this.$t('superRgpd.list.headers.email'),
          value: 'email',
          sortable: false,
        },
        {
          text: this.$t('superRgpd.list.headers.city'),
          value: 'city',
          sortable: false,
        },
        {
          text: this.$t('superRgpd.list.headers.game_date'),
          value: 'game_date',
          sortable: false,
        },
        {
          text: this.$t('superRgpd.list.headers.campaign_id'),
          value: 'campaign_id',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getSuperRgpds: 'superRgpd/getSuperRgpds',
    }),
    searchInput: debounce(function () {
      if (this.options.page !== 1) {
        this.options.page = 1
      } else {
        this.loadSuperRgpds()
      }
    }, 300),
    goToCreate() {
      this.$router.push({ name: 'SuperRgpdCreate' })
    },
    async loadSuperRgpds() {
      this.refreshing = true
      const { page, itemsPerPage, sortBy, sortDesc } = this.options
      await this.getSuperRgpds({ page, itemsPerPage, sortBy, sortDesc, custom: this.search ? this.search : null })
      this.refreshing = false
    },
  },
}
</script>

<style lang="scss" scoped>
.super-rgpd {
  &__table-container {
    @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0;

    &__table {
      @apply tw-h-full tw-flex tw-flex-col;
    }
  }
}
</style>
